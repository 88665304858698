<template>
  <van-nav-bar
    :title="title"
  />
  <van-cell-group>
    <van-cell :title="`累计消费 ${v1}`" style="margin:7px 0;" />
  </van-cell-group>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:7px 0 0 0;">
        <p style="margin:0 0 15px 0;">
          <small>支付金额 {{ item.payAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>时间 {{ item.createTime }}</small>
        </p>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      buyerId: Cookies.get('buyerId'),
      v1: 0
    })
    const getData = () => {
      post('/order.list', {
        pageNum: state.page,
        storeId: state.storeId,
        buyerId: state.buyerId,
        bizScope: 'FREE_PAY',
        status: 'COMPLETED'
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const sum = () => {
      post('/order.sumOrder', {
        buyerId: state.buyerId,
        bizScope: 'FREE_PAY',
        status: 'COMPLETED'
      }).then(res => {
        state.v1 = res.data.payAmount
        console.log(state.v1)
      })
    }
    const init = () => {
      sum()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
